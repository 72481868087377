export default{
  homepage:{
    heading: "Hey there! I'm Tejus",
    subHeading1: "I build cool stuff with code",
    subHeading2: "",
    credit: "© Handcrafted with Reactjs by Tejus Revi",
    srcText: "View source code",
    srcLink: "https://github.com/tejusrevi/personal-website"
  },
  about:{
    name: "Tejus Revi",
    nameML: "തേജസ് രവി",
    jobTitle: "Student at MUN",
    location: "St. John's, Canada",
    credit: "© Handcrafted with Reactjs by Tejus Revi",
    srcText: "View source code",
    srcLink: "https://github.com/tejusrevi/personal-website",
    image:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/tejus-memoji.jpeg?alt=media&token=d3744295-9a7b-452a-a4d2-b05b02582de5"
  },
  education:[
    {
      name:"Memorial University of Newfoundland",
      type:"Bachelor's Degree • Computer Science",
      date:"2021 -2025",
      icon:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/mun.png?alt=media&token=568ac805-68bb-47c2-b8d7-be7760d69ec0",
      years:[
        {
          year: 1,
          courses:[
            "COMP1001 - Introduction to Programming",
            "MATH1000 - Calculus I",
            "MATH2050 - Linear Algebra I",
            "ENGL1090 - Critical Reading and Writing: Telling Stories"
          ]
        }
      ]
    },
    {
      name:"Algonquin College School of Advanced Technology",
      type:"Associate's Degree • Computer Programming",
      date:"2018 -2020",
      icon:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/ac.png?alt=media&token=9c1c62ac-8098-4c72-91e1-68a56601b736",
      years:[
        {
          year: 1,
          courses:[
            "CST2355 - Database Systems",
            "CST8102 - Operating System Fundamentals (GNU/Linux)",
            "CST8284 - Object Oriented Programming (Java)",
            "CST8285 - Web Programming",
            "ENL2019 - Technical Communication for Engineering Technologies",
            "CST8101 - Computer Essentials", 
            "CST8116 - Introduction to Computer Programming",
            "CST8215 - Introduction to Database",
            "MAT8001 - Technical Mathematics for Computer Science",
            "CST8300 - Achieving Success in Changing Environments",
            "ENL1813 - Communications I"
          ]
        },
        {
          year: 2,
          courses:[
            "CST8277 - Enterprise Application Programming",
            "CST8333 - Programming Language Research Project",
            "CST8276 - Advanced Database Topics",
            "CST8334 - Software Development Project",
            "CST2234 - Systems Analysis and Design",
            "CST2335 - Mobile Graphical Interface Programming",
            "CST8109 - Network Programming",
            "CST8288 - Object Oriented Programming with Design Patterns",
            "CST8390 - Business Intelligence and Data Analytics",
          ]
        }
      ]
    },
    {
      name:"Central Board of Secondary Education",
      type:"High School • Mathematics and Computer Science",
      date:"2016 -2018",
      icon:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/cbse.png?alt=media&token=e431dcac-9fc8-4600-99bb-decf994a0163",
      years:[]
    }
  ],
  skills:{
    languages: ["JavaScript", "Python", "Java", "PHP"],
    web: ["Django", "ReactJS", "NodeJS"],
    mobile: ["Android", "React Native"],
    data: ["numpy"],
    cloud: ["Firebase", "Digital Ocean"]
  },
  projects:[
    {
      name: "Workout Tracker",
      id:"workout-tracker",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fworkout-tracker.mp4?alt=media&token=eecacfbd-4e53-4c76-ac33-0a6fb47002bc"
      },
      description: "A full-stack web application that enables users to design, customize, and manage workout programs, with the option to share them publicly or keep them private within a fitness community. The app features secure Google login, user authentication, and a streamlined interface for tracking routines.",
      tags: ["JavaScript", "Bootstrap", "Web", "MongoDB", "Google Cloud Platform", "CSS", "HTML", "NoSQL"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/workout-tracker"}
      ]
    },
    {
      name: "New York City Housing Preservation and Development Data Analysis",
      id:"nyc-housing",
      media:{
        type: "image",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fnyc.jpg?alt=media&token=c2745dd8-8604-4aa5-862d-3863163d065b"
      },
      description: "Analysis of two datasets from New York City's Department of Housing Preservation and Development, focusing on affordable housing by building and project type. Goal was to predict affordable buildings, identify projects responsible for housing affordability, and map affordability across NYC using geographic data.",
      tags: ["Python", "Numpy", "Pandas", "Data Science"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/nyc-housing/blob/master/project.ipynb"},
      ]
    },
    {
      name: "Interactive Head",
      id:"interactive-head",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Finteractive-head.mp4?alt=media&token=9ee8b24b-1b2d-449c-b9b6-60bdc9fd40e3"
      },
      description: "A threejs based project that render a 3D model of human head onto an HTML canvas. The model is controlled real-time based on cursor position or gyroscopic movements.",
      tags: ["3D Modeling", "JavaScript", "Threejs", "ReactJS", "HTML"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/interactive-human-face"},
        {name: "Website",
        href: "https://interactive-head.web.app/"}
      ]
    },
    {
      name: "Mapogram",
      id:"mapogram",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmapogram.mp4?alt=media&token=7dd3d7f8-f4fd-4f1b-a64f-caebf9d8a2ee"
      },
      description: "A React based project that lets users save their favorite locations and street views on the world map.",
      tags: ["Web", "Google Cloud Platform", "Firebase", "JavaScript", "ReactJS", "HTML", "CSS"],
      links:[
        {name: "GitHub",
        href: "https://github.com/tejusrevi/mapogram"},
        {name: "Website",
        href: "https://map-o-gram.web.app"}
      ]
    },
    {
      name: "Star Wars Title Crawl",
      id:"star-wars-title-crawl",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fstar-wars-title-crawl.mp4?alt=media&token=f7a62ca3-907e-4d00-bff1-0df012db5994"
      },
      description: "A threejs based project that render Star Wars opening title scene with user generated text.",
      tags: ["JavaScript", "Threejs", "Firebase", "ReactJS", "HTML", "CSS"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/star-wars-title-crawl"},
        {name: "Website",
        href: "https://star-wars-title.web.app/"}
      ]
    },
    {
      name: "Morse Code Translator",
      id:"morse-code-translator",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmorse-code-translator.mp4?alt=media&token=51535a27-5ebc-43fa-b1f0-56489c32a695"
      },
      description: "A web interface for translating English text into Morse Code using ITU standard. Provides visual and auditory output.",
      tags: ["JavaScript", "ReactJS", "Vantajs", "HTML", "CSS"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/morse-code-translator"}
      ]
    },
    {
      name: "Password Book",
      id:"password-manager",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fpm.mp4?alt=media&token=806551a8-556e-482f-a56b-1bd8ad5e3a43"
      },
      description: "Desktop application that lets users save multiple passwords, all of which can be accessed using a master key. All paswords are stored in a text file in encrypted format.",
      tags: ["Python", "Tkinter", "Desktop"],
      links:
      [
        {name: "GitHub",
        href: "https://github.com/tejusrevi/password-manager"}
      ]
    },
    {
      name: "Movie Catalog + Recommendation Engine (Academic Project)",
      id:"movie-catalog",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fmovie-catalog.mp4?alt=media&token=d114b5ea-8c9a-49a5-8bb1-1256837eeaa3"
      },
      description: "Capstone project for CST8276 Advanced Database course done with a group of 3 members. The project involved sourcing movie data, building a web catalog and recommending similiar movies based on user selection.",
      tags: ["Django", "Python", "BS4", "MongoDB", "NoSQL", "Web", "PyMongo", "JSON", "Data Science", "HTML", "CSS"],
      links:
      [
        {name: "Demo",
        href: "https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/demo%2Fmovie-catalog-demo.mp4?alt=media&token=d457c88b-5f7b-4b0d-969a-26661a5f18d7"}
      ]
    },
    {
      name: "Guardian News App (Academic Project)",
      id:"guardian",
      media:{
        type: "video",
        src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/projects%2Fguardian.mp4?alt=media&token=db0b8b2d-46dd-420d-a8d6-f07a9ea9fc31"
      },
      description: "Capstone project for CST2335 Mobile Graphics Programming course done with a group of 4 members. The project displays news articles collected using Guardian API, along with CRUD actions on the data.",
      tags: ["Mobile", "Android", "Java", "API"],
      links:
      [
        {name: "APK",
        href: "https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/downloadables%2Fapp-release.apk?alt=media&token=d0d54c3c-027c-42d7-b6e1-fbaf6c223630"}
      ]
    }
  ],
  tags:[
    {
      name: "Web",
      type: "topic"
    },
    {
      name: "Desktop",
      type: "topic"
    },
    {
      name: "Data Science",
      type: "topic"
    },
    {
      name: "Mobile",
      type: "topic"
    },
    {
      name: "JavaScript",
      type: "language"
    },
    {
      name: "Python",
      type: "language"
    },
    {
      name: "Java",
      type: "language"
    },
    {
      name: "ReactJS",
      type: "framework"
    },
    {
      name: "Django",
      type: "framework"
    },
    {
      name: "Threejs",
      type: "library"
    },
    {
      name: "Numpy",
      type: "library"
    },
    {
      name: "Pandas",
      type: "library"
    },
    {
      name: "Scikit-Learn",
      type: "library"
    },
    {
      name: "MongoDB",
      type: "database"
    },
    {
      name: "Firebase",
      type: "platform"
    },
    {
      name: "Google Cloud Platform",
      type: "platform"
    },
    {
      name: "HTML",
      type: "format"
    },
    {
      name: "CSS",
      type: "format"
    },
    {
      name: "NoSQL",
      type: "format"
    },
  ],
  drawings:[
    {
      name: 'pursuit-of-happyness',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/pursuit_of_happyness.jpg?alt=media&token=b80a257d-09fd-4dc6-8b94-f5e94d3c259a"
    },
    {
      name: 'ironman',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/ironman.jpeg?alt=media&token=db91ccc2-dbe1-44a2-ba6f-2322965dce4b"
    },
    {
      name: 'lion-king',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/lion-king.jpg?alt=media&token=e6b20742-2534-4b03-a1ab-e39fb6ce8708"
    },
    {
      name: 'adele',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/adele.jpg?alt=media&token=dd75b098-d08e-4cba-a427-3b98fe429d53"
    },
    {
      name: 'wonder-woman',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/wonder_woman.jpeg?alt=media&token=d4aec158-19cc-4df7-b4ce-22172b721c7d"
    },
    {
      name: 'alexandra-daddario',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/alexandra-daddario.jpg?alt=media&token=beff3c2c-a36c-41cd-98e6-88cbed1fafd4"
    },
    {
      name: 'emilia-clarke',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/emilia-clarke.jpeg?alt=media&token=9a5af1c1-a1b1-47a3-8e28-95c3f4578ca4"
    },
    {
      name: 'harry-potter',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/harry-potter.jpeg?alt=media&token=7f97c6ee-b704-41d8-afff-ebe7707ac90f"
    },
    {
      name: 'katy-perry',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/katy_perry.jpeg?alt=media&token=86b215f8-0076-4ba1-a56c-dd86092d1209"
    },
    {
      name: 'joker',
      src:"https://firebasestorage.googleapis.com/v0/b/tejus-dev.appspot.com/o/joker.jpeg?alt=media&token=825c5668-6d72-453b-9b6d-f0c7c6a043ae"
    }
  ]
}